<template>
  <div class="accordion" id="generationAccordion">
    <div class="accordion-item">
      <h2 class="accordion-header" id="generationAccordionHeading">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generationAccordionCollapse" aria-expanded="true" aria-controls="generationAccordionCollapse">
          Generate topics
        </button>
      </h2>
      <div id="generationAccordionCollapse" class="accordion-collapse collapse" aria-labelledby="generationAccordionHeading" data-bs-parent="#generationAccordion">
        <div class="accordion-body">
          <div class="row row-cols-1 row-cols-lg-2 g-4 mt-3">
            <div class="col" v-for="topicCard in topicCards" :key="topicCard.promptId">
              <div class="card h-100" >
                <div class="card-body">
                  <h5 class="card-title"><input type="date" class="form-control" :id="'topicDate' + topicCard.promptId" :name="'topic_date' + topicCard.promptId" pattern="\d{4}-\d{2}-\d{2}" v-model="topicCard.date"></h5>
                  <p class="card-text">{{ topicCard.prompt }}</p>
                  <p class="card-text" v-if="topicCard.generatedTopic"><textarea class="form-control" :id="'generatedTopic' + topicCard.promptId" rows="5" v-model="topicCard.generatedTopic"></textarea></p>
                  <p class="card-text text-muted" v-if="topicCard.loading">Loading...</p>
                  <p class="card-text text-danger" v-if="topicCard.error">{{ topicCard.error }}</p>
                </div>
                <div class="card-footer d-flex justify-content-between align-items-center">
                  &nbsp;
                  <div>
                    <button @click="generateTopic(topicCard)" type="button" class="btn btn-primary" v-if="!topicCard.loading && !topicCard.saved">
                      <i class="fa fa-lightbulb-o"></i> Generate topic
                    </button>
                    <button @click="saveTopic(topicCard)" type="button" class="btn btn-primary" v-if="topicCard.generatedTopic && !topicCard.loading && !topicCard.saved">
                      <i class="fa fa-save"></i> Save
                    </button>
                    <span v-if="topicCard.saved" class="text-muted">Saved</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      prompts: {
        type: Array,
        required: true
      },
      date: {
        type: Date,
        required: true
      },
    },
    data() {
      let topicCards = [];

      for (let i = 0; i < this.prompts.length; i++) {
        topicCards.push({
          promptId: this.prompts[i].id,
          prompt: this.prompts[i].prompt,
          date: this.date,
          generatedTopic: null,
          loading: false,
          error: null,
          saved: false
        });
      }

      return {
        topicCards: topicCards
      };
    },
    methods: {
      async generateTopic(topicCard) {
        topicCard.loading = true;
        topicCard.error = null;
        topicCard.saved = false;
  
        try {
          const response = await axios.post('/api/topics/generate', {
            prompt_id: topicCard.promptId,
            topic_date: topicCard.date,
          });

          topicCard.generatedTopic = response.data;
        } catch (err) {
          topicCard.error = err.message;
        } finally {
          topicCard.loading = false;
        }
      },
      async saveTopic(topicCard) {
        try {
          const response = await axios.post('/api/topics', {
            topic: topicCard.generatedTopic,
            topic_date: topicCard.date ? topicCard.date : null,
          });

          if (response.status === 201) {
            topicCard.saved = true;
            //this.$set(topicCard, 'saved', true);
          }
        } catch (err) {
          console.error('Error saving topic:', err.message);
        }
      },
    },
  };
  </script>
  