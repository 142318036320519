/**
 * Make an ajax call to API on change event to send the value of the element
 *
 * Use in blade template as:
 *   v-put-value-to-api:parameter="config"
 * where config is a json object containing url and optionally success and error
 *
 * For example:
 *   <input type="checkbox" v-put-value-to-api:public="{{ json_encode(['url' => route('routename', $object), 'success' => 'Public value saved', 'error' => 'Could not save public value']) }}" />
 */
export default {
    bind: function(el, binding, vnode) {
        const vm = vnode.context;
        const autoHideDelay = 5000;
        const errorAutoHideDelay = 10000;
        const toasterPosition = "b-toaster-bottom-left";
        let successMessage = "Data saved!";
        let errorMessage = "";

        el.addEventListener("change", function() {
            // url is required
            if (!binding.value.url) {
                if (el.type == "checkbox") {
                    // In case of checkbox retoggle it to previous state
                    el.checked = !el.checked;
                }

                // Show an error message toast
                /*
                vm.$bvToast.toast(
                    "Configuration error! Please notify the admin.",
                    {
                        variant: "danger",
                        title: "Error",
                        autoHideDelay: errorAutoHideDelay,
                        toaster: toasterPosition,
                        appendToast: true
                    }
                );
                */

                return;
            }
            // success message is optional
            if (binding.value.success) {
                successMessage = binding.value.success;
            }
            // error message is optional
            if (binding.value.error) {
                errorMessage = binding.value.error + " ";
            }
            let data = {};

            if (el.type == "checkbox") {
                // In case of checkbox we don't send the value of the element, but a value based on "checked" instead
                data[binding.arg] = el.checked ? 1 : 0;
            } else {
                data[binding.arg] = el.value;
            }

            window.axios
                .put(binding.value.url, data)
                .then(function(response) {
                    // Show a success message toast
                    /*
                    vm.$bvToast.toast(successMessage, {
                        variant: "success",
                        title: "Success",
                        autoHideDelay: autoHideDelay,
                        toaster: toasterPosition,
                        appendToast: true,
                    });
                    */
                })
                .catch(function(error) {
                    if (el.type == "checkbox") {
                        // In case of checkbox retoggle it to previous state
                        el.checked = !el.checked;
                    }

                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.message) {
                                errorMessage += error.response.data.message;
                            } else if (error.response.data.error) {
                                errorMessage += error.response.data.error;
                            }
                        }
                    } else if (error.request) {
                        errorMessage += "Request error";
                    } else {
                        errorMessage += error.message;
                    }

                    // Show an error message toast
                    console.log(errorMessage);
                    /*
                    vm.$bvToast.toast(errorMessage, {
                        variant: "danger",
                        title: "Error",
                        autoHideDelay: errorAutoHideDelay,
                        toaster: toasterPosition,
                        appendToast: true,
                    });
                    */
                });
        });
    },
    unbind: function(el, binding) {
        el.removeEventListener("change");
    }
};
