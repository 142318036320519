<template>
  <div>
    <div class="form-group mb-2">
      <label for="genre">Genre</label>
      <input type="text" class="form-control" id="genre" v-model="genre">
    </div>
    <div class="form-group mb-2">
      <label for="genre">Era</label>
      <input type="text" class="form-control" id="era" v-model="era">
    </div>
    <div class="form-group mb-2">
      <label for="genre">Comment</label>
      <input type="text" class="form-control" id="comment" v-model="comment">
    </div>
    <div class="d-flex justify-content-center">
      <button type="button" class="btn btn-secondary me-2" @click.prevent="randomizePrompt()"><i class="fa fa-refresh"></i> Randomize prompt</button>
      <button @click="loadRecommendations" type="button" class="btn btn-primary"><i class="fa fa-search"></i> Show AI recommendations</button>
    </div>

    <div class="row row-cols-1 row-cols-lg-2 g-4 mt-3" v-if="songs.length > 0">
      <div class="col" v-for="song in songs" :key="song.youtube_id">
        <div class="card h-100 border-primary">
          <div class="ratio ratio-16x9">
            <iframe v-if="song.youtube_id" :src="'https://www.youtube-nocookie.com/embed/' + song.youtube_id" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </div>
          <div class="card-body">
            <h5 class="card-title"><i v-if="!isYoutubeValid(song)" class="fa fa-exclamation-circle text-danger me-2"></i><a :href="'https://www.youtube.com/watch?v=' + song.youtube_id" class="card-link" target="_blank">{{ song.youtube_title }}</a></h5>
            <h6 class="card-subtitle mb-2">🧑 <strong>{{ song.artist }}</strong></h6>
            <h6 class="card-subtitle mb-2">🎵 {{ song.title }}</h6>
            <p class="card-text"><textarea class="form-control" rows="5" v-model="song.comment"></textarea></p>
          </div>
          <div class="card-footer d-flex justify-content-between align-items-center">
            &nbsp;
            <button
              type="button"
              class="btn btn-sm"
              :class="{'btn-primary': isYoutubeValid(song), 'btn-danger': !isYoutubeValid(song)}" 
              v-if="!song.saved"
              @click.prevent="saveSong(song)"
            ><i class="fa fa-save"></i> Save</button>
            <span v-if="song.saved" class="text-muted">Saved</span>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <p v-if="loading">Loading...</p>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        songs: [],
        loading: false,
        genres: ["Pop", "Rock", "Progressive Rock", "Jazz", "Electronic", "Hip Hop", "Folk", "Country", "Blues", "R&B", "Reggae", "World", "Indie", "Metal", "Punk", "Soul", "Funk", "Disco", "House", "Techno", "Drum'n'Base"],
        genre: "Pop",
        eras: ["60s", "70s", "80s", "90s", "2000s", "2010s", "2020s"],
        era: "90s",
        comments: ["Comment contains a short description about how this song was made", "Comment contains a short fact about the artist", "Comment contains a short quote from the song", "Comment contains a few sentences about the era", "Comment contains a few sentences about the inspiration behind this song", "Comment contains a short reasoning behind why it's recommended"],
        comment: "Comment contains a short description about how this song was made",
        error: "",
      };
    },
    methods: {
      async loadRecommendations() {
        this.loading = true;
        this.error = "";
  
        try {
            const response = await axios.get('/api/songs/recommendations',  { params: { genre: this.genre, era: this.era, comment: this.comment } });
            this.songs = response.data;
        } catch (err) {
            this.error = err.message;
        } finally {
            this.loading = false;
        }
      },
      async saveSong(song) {
        try {
          const response = await axios.post('/api/songs', {
            title: song.title,
            artist: song.artist,
            youtube_id: song.youtube_id,
            comment: song.comment,
          });

          if (response.status === 201) {
            this.$set(song, 'saved', true);
          }
        } catch (err) {
          console.error('Error saving song:', err.message);
        }
      },
      randomizePrompt() {
        this.genre = this.genres[Math.floor(Math.random() * this.genres.length)];
        this.era = this.eras[Math.floor(Math.random() * this.eras.length)];
        this.comment = this.comments[Math.floor(Math.random() * this.comments.length)];
      },
      isYoutubeValid(song) {
        if (!song.youtube_id || !song.youtube_title || !song.artist || !song.title) {
          return false;
        }

        return song.youtube_title.includes(song.title) && song.youtube_title.includes(song.artist);
      },
    },
    mounted: function () {
      this.randomizePrompt();
    }
  };
  </script>
  